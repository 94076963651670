<script>
export default {
  name: "pageLoading",
  data(){
    return {
      showLoading:true,
    }
  }
}
</script>

<template>
  <van-overlay :show="showLoading" >
    <div class="wrapper">
      <van-loading  type="spinner" color="#FFFFFF" size="64px" text-size="14px" vertical style="z-index: 99">
        <div>加载中...</div>
      </van-loading>
    </div>

  </van-overlay>
</template>

<style scoped lang="less">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>