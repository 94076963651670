<template>
    <div class="examClass">
        <van-nav-bar class="cart-title" fixed @click-left="onBack" @click-right="isDelete = !isDelete">
            <template #left>
                <van-icon name="arrow-left" color="#646566" size="3rem" style="padding: 0 1rem;" />
            </template>
            <template #title>{{questionCateTitle}}</template>
            <!-- <template #right >
                <van-dropdown-menu class="rightDropdown"  active-color="#1989fa">
                <van-dropdown-item v-model="value1" :options="option1" />
                </van-dropdown-menu>
            </template> -->
        </van-nav-bar>

        <div class="question-subject-tab" v-if="subjectList.length>0">
            
            <van-cell class="clock" title="累计打卡" center>
                <template #title>累计打卡<span>0</span>天</template>
            </van-cell>
            <van-tabs class="subject-tab" v-model:active="active" color="#1989fa" animated swipeable @click="clickTab">
                <van-tab :title="item.abbreviation_name" :name="item.id" v-for="(item,index) in subjectList" :key="index">
                    <div v-if="item.data.length > 0">
                       <div v-for="(item2,index2) in item.data" :key="index2">
                           <van-cell class="question-exam" center @click="questionDetail(item2.id)">
                               <template #title>
                                   <span>{{item2.dayTime.slice(5)}}</span>，今日打卡 <span>{{ item2.count }}人</span>
                               </template>
                               <!-- <template #icon>
                                   <van-icon class="left-icon01" :name="icon_exam"/>
                               </template> -->
                               <template #right-icon>
                                   <div>
                                       <van-tag v-if="item2.questionStatus" color="#646566" round plain size="medium" @click.stop="result(item2.testPaperAnswerId,item2.id,item2.title,1)">查看</van-tag>
                                       <van-tag v-else type="primary" round size="medium">打卡</van-tag>
                                   </div>
                               </template>
                               <template #label>
                                   <span>{{item2.title}}</span>
                               </template>

                           </van-cell>
                       </div>
                    </div>
                    <div v-else>
                        <van-empty
                                description="暂无试卷"
                                image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                                image-size="24rem">
                            <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus" @click="mySchool">联系客服</van-button>
                        </van-empty>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <div v-else>
            <van-empty
                    description="暂无试卷"
                    image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                    image-size="24rem">
                <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus" @click="mySchool">联系客服</van-button>
            </van-empty>
        </div>
        <!-- 弹出框（试卷状态） -->
        <van-popup v-model="showKS" class="question-content1" v-if="currentInfo.state == '0'">
            <div class="question-content-popup">
                <van-icon name="warning-o" color="#ee0a24"/>
                <div>该试卷还在整理中，暂未上架，敬请期待哦~</div>
            </div>
        </van-popup>
        <!-- 弹出层（考试信息） -->
        <van-popup v-model="showKS" :style="{ height: '70%',width:'95%' }" v-if="currentInfo.state == '1'">
            <examKsxx :currentInfo="currentInfo" :type="2" @closeFun="closeFun"></examKsxx>
        </van-popup>
        <van-popup class="resultPop" v-model="showSTQKFK" position="right" :style="{ height: '100%', width: '100%' }">
            <zxksKsdtqk :testPaperAnswerId="testPaperAnswerId" :testPaperId="testPaperId" :type="examination_type" url="QuestionDailyPracticeList" :title="testPaperTitle" @againQuestion="againQuestion"></zxksKsdtqk>
        </van-popup>
    </div>
</template>

<script>
    // 考试信息的弹框
    import examKsxx from '@/modules/exam_ksxx'
    import zxksKsdtqk from '@/modules/zxks_ksdtqk'
    import {Toast} from "vant";
    import {dailyPracticeTestPaper, testPaperList, testPaperDetail} from "@/request/api";

    export default {
        name: 'QuestionList',
        metaInfo: {
            title: '题库列表', //设置页面title
            meta: [{                 //设置页面meta
                name: 'keyWords',
                content: '关键词'
            },
                {
                    name: "description",
                    content: "描述",
                },
            ]
        },
        components: {examKsxx,zxksKsdtqk},
        data() {
            return {
                value1: 0,
                option1: [
                    { text: '中药学专业知识一', value: 0 },
                    { text: '中药学专业知识二', value: 1 },
                    { text: '中药学综合知识与技能', value: 2 },
                    { text: '药事管理与法规', value: 3 },
                ],
                activeNames: ['1'],
                active: '',
                item: '',
                selectChapter: true,
                selectPaper: false,
                icon_exam: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_exam.png",
                icon_plus: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_plus.png",
                icon_minus: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_minus.png",
                icon_choice: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_choice.png",
                show: false,
                showKS: false,
                msg: '',
                // 考试数据
                examObj: {},
                // 传给子组件的考当前试信息
                currentInfo: {},

                question_type_id: '',
                questionCateTitle: '题库',
                subject_id: '',
                subjectList: [],
                testPaperList: [],
                appOrH5:false,
                showSTQKFK:false,
                testPaperAnswerId:'',
                testPaperId: '',
                examination_type: '',
                testPaperTitle:''

            }
        },
        created() {
            this.question_type_id = this.$route.query.id;
            this.questionCateTitle = this.$route.query.title;
            this.appOrH5 = this.$route.query.appOrH5;
            // 获取考试列表
            this.getTestPaperTab();
        },

        methods: {   
            mySchool(){
                this.$router.push('/MySchool')
            },
            // 点击考试信息的遮罩层
            closeFun() {
                this.showKS = false
            },

            onQuestionChapter() {
                if (this.selectChapter == true) {
                    this.selectChapter = false
                } else if (this.selectChapter == false) {
                    this.selectChapter = true
                }
            },
            //试卷详情
            ksxxFun(val) {
                this.showKS = true
                this.currentInfo = val
            },

            toPaper() {
                // this.$router.push('/PaperDetail')
                this.$router.push('/Zxks')
            },

            onBack() {
                // history.back();
                // this.$router.push('/questionH5')
                let url = 'Question';
                if(window.android != undefined) {
                    if(window.android.isapp() == true) {
                        url = 'QuestionH5';
                    }
                }
                this.$router.push({
                    name:url,
                    params:{

                    },
                    query:{

                    }
                });
            },
            getTestPaperTab() {
                let that = this;
                let question_type_id = that.question_type_id;
                dailyPracticeTestPaper({type: question_type_id})
                    .then(res => {
                        if (res.data.code == 1) {
                            that.subjectList = res.data.data.data;
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            clickTab(name, title) {

            },
            //试卷详情
            questionDetail(id) {
                let that = this;
                testPaperDetail({test_paper_id: id})
                    .then(res => {
                        if (res.data.code == 1) {
                            that.showKS = true
                            that.currentInfo = res.data.data.data
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            //未购买 提示
            orderMsg(id) {
                Toast({
                    message: '题库未购买',
                    icon: 'lock',
                    position: 'top',
                    duration: 1000,
                });
            },
            result(id,test_paper_id,title,examination_type) {
                this.testPaperAnswerId = id;
                this.testPaperId = test_paper_id;
                this.examination_type = examination_type;
                this.testPaperTitle = title;
                this.showSTQKFK = true;
            },
            againQuestion() {
                this.showSTQKFK = false;
                this.testPaperAnswerId = '';
                this.testPaperId = '';
                this.examination_type = '';
                this.testPaperTitle = '';
            },
        },
        computed: {},
    }
</script>

<style lang="less">
    #app {
        background-color: #ffffff;
    }

    .examClass .van-popup {
        border-radius: 0rem;
        overflow-y: scroll;
    }
    .clock {
        margin: 0 2%;
        width: 96%;
        border-radius: 1rem;
        padding: 1.5rem;
        color: #ffffff;
        background-image: linear-gradient(-225deg, #5D9FFF 0%, #B8DCFF 48%, #6BBBFF 100%);
    }
    .clock  .van-cell__title span {
        color: #f2f53f;
        padding: 0 0.5rem;
        font-size: 2.4rem;
    }

    .question-subject-tab {
        padding: 12.5rem 0.5rem 0.5rem 0.5rem;
        background-color: #ffffff;
    }

    .question-subject-tab .subject-tab .van-tabs__wrap {
        position: fixed;
        top: 6rem;
        // z-index: 99;
        width: 100%;
    }

    .question-subject-tab .subject-tab .van-tabs__line {
        bottom: 0.5rem !important;
        left: 0 !important;
        z-index: 1;
        width: 3.2rem !important;
        height: 0.5rem !important;
        border-radius: 2rem !important;
    }

    .question-subject-tab .subject-tab .van-tab .van-tab__text {
        padding: 0 0.6rem;
    }

    .question-subject-tab .subject-tab .van-tab--active .van-tab__text {
        font-size: 2.2rem !important;
    }

    // .question-subject-tab .subject-tab .van-tabs__content {
    //   margin: 1rem 0;
    // }

    .question-exam {
        margin: 1.5rem 2% !important;
        padding: 1.5rem 1rem 1.5rem 1.3rem !important;
        box-shadow: 0 0 0.5rem rgb(219, 219, 219);
        border-radius: 0.8rem !important;
        width: 96% !important;
    }

    .question-exam .left-icon01 {
        font-size: 4rem !important;
        padding: 1rem 1rem 1rem 0rem !important;
        background: #ffffff;
        z-index: 2;
    }

    .question-exam .left-icon {
        font-size: 2.6rem !important;
        padding: 1rem 1rem 1rem 0rem !important;
        background: #ffffff;
        z-index: 2;
    }

    .question-exam .right-icon {
        font-size: 3rem !important;
        // color: #1989fa;
        padding: 0 0.3rem 0 0rem !important;
    }

    .question-exam .van-cell__title {
        font-size: 1.8rem;
        color: #969799;
    }

    .question-exam .van-cell__title span {
        color: #646566;
    }

    .question-exam .van-cell__label {
        line-height: 2.4rem !important;
        font-size: 1.8rem !important;
        padding-top: 0.6rem;
    }

    .question-child {
        margin-left: 3rem;
    }

    .question-child .vertical-line {
        height: 100%;
        width: 0.1rem;
        background-color: #e7e9ed;
        position: absolute;
        left: 2.3rem;
    }

    .question-progress {
        float: left;
        margin: 0.8rem 1rem 0 0 !important;
        display: flex;
        justify-content: center;
    }

    .question-progress .van-progress__pivot {
        display: none;
    }

    .question-exam-chapter {
        font-weight: 600 !important;
        font-size: 2.1rem;
    }

    .question-exam-collapse .van-collapse-item {
        padding: 0rem 1rem 0rem 3.5rem !important;
    }

    .question-exam-collapse .vertical-line {
        height: 100%;
        width: 0.1rem;
        background-color: #e7e9ed;
        position: absolute;
        left: 1.3rem;
    }

    .question-exam-collapse .van-collapse-item .van-collapse-item__title {
        align-items: center;
        padding: 1.5rem 0 !important;
    }

    .question-exam-collapse .van-collapse-item .van-collapse-item__title:active {
        background-color: #ffffff;
    }

    .question-exam-collapse .van-collapse-item .question-exam-list {
        // line-height: 6rem;
        display: flex;
        align-items: center;
        padding-right: 1rem;
    }

    .question-exam-collapse .van-collapse-item .question-exam-list .left-icon {
        font-size: 2.6rem !important;
        padding: 1rem 1rem 1rem 0rem !important;
        background: #ffffff;
        z-index: 2;
    }

    .question-exam-collapse .van-collapse-item .right-icon {
        font-size: 3rem !important;
        padding: 1.5rem !important;
        background: #ffffff;
        z-index: 2;
    }

    .question-exam-collapse .van-collapse-item .question-exam-list .label {
        font-size: 1.8rem;
        color: #969799;
    }

    .question-exam-collapse .van-collapse-item .van-icon-arrow {
        line-height: 6rem;
        display: flex;
        align-items: center;
        font-weight: 600;
    }


    .question-exam-collapse::after {
        border-width: 0 !important;
    }

    .van-collapse-item__title::after {
        display: none !important;
    }

    .question-exam-collapse .van-collapse-item .question-exam-children {
        padding: 1.5rem 0 !important;
    }

    .question-exam-collapse .van-collapse-item .question-exam-children .left-icon {
        font-size: 2.6rem !important;
        padding: 1rem 1rem 1rem 0rem !important;
        background: #ffffff;
        z-index: 2;
    }

    .question-exam-collapse .van-collapse-item .question-exam-children .vertical-line {
        height: 100%;
        width: 0.1rem;
        background-color: #e7e9ed;
        position: absolute;
        left: 1.21rem;
    }

    .question-exam-collapse .van-collapse-item .question-exam-children .van-cell__label {
        line-height: 2.4rem !important;
        font-size: 1.8rem !important;
        padding-top: 1rem;
    }

    .question-exam-collapse .van-collapse-item .question-exam-children::after {
        border-width: 0 !important;
    }

    .question-content1 {
        border-radius: 1rem;
    }

    .question-content1 .question-content-popup {
        padding: 2rem 1.5rem;
        border-radius: 1rem;
        font-size: 2rem;
        line-height: 3rem;
        text-align: center;
    }

    .question-content1 .question-content-popup .van-icon {
        padding: 1rem 1.5rem;
        font-size: 3.5rem;
        line-height: 3rem;
    }
    .rightDropdown .van-dropdown-menu__bar {
      height: 4rem !important;
    }
    .rightDropdown .van-dropdown-menu__bar .van-dropdown-menu__title {
      padding: 0 1.8rem 0 .5rem;
      color: #323233;
      font-size: 2rem;
      line-height: 3rem;
    }
    .rightDropdown .van-dropdown-menu__bar .van-dropdown-menu__title::after {
      position: absolute;
      top: 20%;
      right: 0rem;
      margin-top: 0rem;
      border: 0.6rem solid;
      border-color: transparent transparent #adb1b4 #bcbec0;
      content: '';
    }
    .rightDropdown .van-dropdown-item__content {
      padding-top: 1rem;
    }
    .rightDropdown .van-dropdown-item .van-dropdown-item__option {
        padding: 0 1.5rem;
        align-items: center;
    }
    .rightDropdown .van-dropdown-item {
        z-index: 100;
    }
</style>




