<template>
  <div class="zxks_ksxx">
    <div class="zxks_ksxx_top" @click="closeFun">{{currentInfo.title}}</div>
    <!-- 考试信息 -->
    <div class="zxks_ksxx_mask" @click="closeFun">
      <div class="borderB">
        <div>
          <img src="http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_zongfen.png" alt>
        </div>
        <div class="marginT2">
          <div>试卷总分</div>
          <div class="fontW">{{currentInfo.mf}}分</div>
        </div>
      </div>
      <div class="borderB borderL">
        <div>
          <img src="http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_jige.png" alt>
        </div>
        <div class="marginT2">
          <div>及格分</div>
          <div class="fontW">{{currentInfo.jgf}}分</div>
        </div>
      </div>
      <div>
        <div>
          <img src="http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_zongti.png" alt>
        </div>
        <div class="marginT2">
          <div>总题数</div>
          <div class="fontW">{{currentInfo.zts}}题</div>
        </div>
      </div>
      <div class="borderL">
        <div>
          <img src="http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_shichang.png" alt>
        </div>
        <div class="marginT2">
          <div>考试时长</div>
          <div class="fontW">{{currentInfo.kssc}}分钟</div>
        </div>
      </div>
    </div>
    <!-- 考试时间 -->
    <div class="zxks_ksxx_time" @click="closeFun">
    
        <van-cell title="考试时间" >
          <template #icon>
            <img src="http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_shijian.png" alt>
          </template>
          <template #label>
            <div>
              {{currentInfo.dateStart}}
              <span style="marginleft: 1vw">{{currentInfo.timeStart}}</span>
              ~
              {{currentInfo.dateEnd}}
              <span
                style="margin-left:1vw"
              >{{currentInfo.timeEnd}}</span>
            </div>
          </template>
        </van-cell>

    </div>
    <!-- 考试说明 -->
    <div class="zxks_ksxx_kssm" @click="closeFun">
          <van-cell title="考试说明" >
          <template #icon>
            <img src="http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_kaoshishuoming.png" alt>
          </template>
          <template #label>
            <div>{{currentInfo.kssm}}</div>
          </template>
        </van-cell>
    </div>
    <div class="zxks_ksxx_btn">
      <van-button round type="info" :disabled="true" v-if="currentInfo.state==='0'">未开始</van-button>
      <van-button round type="info" v-if="currentInfo.state==='1'" @click="datiFun()">开始答题</van-button>
      <van-button round type="info" :disabled="true" v-if="currentInfo.state==='2'">已结束</van-button>
    </div>
  </div>
</template>
 
<script>
//import * from '@/*/*'
export default {
  components: {},
  props: ['currentInfo'],
  data() {
    return {}
  },
  watch: {},
  created() {},
  methods: {
    // 关闭考试信息的弹框
    closeFun() {
      this.$emit('closeFun')
    },
    // 开始答题
    datiFun() {
      this.$router.push({
        name: 'zxksDT',
        query: { time: this.currentInfo.kssc }
      })
    }
  }
}
</script>
 
<style scoped>
.zxks_ksxx {
  position: relative;
  overflow-y: auto;
  height: 100%;
}
.zxks_ksxx::-webkit-scrollbar {
  display: none;
}
.zxks_ksxx_top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15rem;
  font-size: 2.4rem;
  color: #fefffe;
  line-height: 3rem;
  padding-top: 6rem;
  text-align: center;
  color: #ffffff;
  background-image: url('http://cdnoss.ksyt.com.cn/wxImages/2022yaoshi_yuekao.jpg');
  background-size: 100% 100%;
}
.zxks_ksxx_mask {
  /* width: 100%; */
  height: 18rem;
  margin: 15rem 2.5rem 4rem 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 0 1rem rgb(211, 209, 209);
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.zxks_ksxx_mask > div {
  width: 49%;
  display: flex;
  flex-wrap: wrap;
  height: 9rem;
}
.zxks_ksxx_mask > div > div {
  /* width: ; */
  padding: 1.5rem 0rem 1.5rem 1.5rem;
}
.zxks_ksxx_mask img {
  width: 6rem;
  height: 6rem;
  text-align: center;
}
.marginT2 {
  font-size: 1.8rem;
  line-height: 3rem;
  color: #323233;
  text-align: left;
}
.fontW {
  font-weight: bold;
  line-height: 3rem;
}
.borderL {
  border-left: 0.1rem solid #e7e9ed;
}
.borderR {
  border-right: 0.1rem solid #e7e9ed;
}
.borderT {
  border-top: 0.1rem solid #e7e9ed;
}
.borderB {
  border-bottom: 0.1rem solid #e7e9ed;
}
/* // 考试时间 */
.zxks_ksxx_time {
  height: 6rem;
  margin: 0 2.5rem;
  position: relative;
  top: 37rem;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  /* border-bottom: 1px solid #e7e9ed; */
}
.zxks_ksxx_time .van-cell {
  width: 100%;
}
.zxks_ksxx_time img {
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
}
.zxks_ksxx_time .van-cell__label {
  line-height: 3rem;
}
.zxks_ksxx_time .van-cell__label > div {
  font-size: 1.8rem;
}

/* 考试说明 */
.zxks_ksxx_kssm {
  height: auto;
  margin: 0 2.5rem;
  line-height: 3rem;
  position: relative;
  top: 39rem;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}
.zxks_ksxx_kssm .van-cell {
  width: 100%;
}
.zxks_ksxx_kssm img {
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
}
.zxks_ksxx_kssm .van-cell__label {
  line-height: 3rem;
}
.zxks_ksxx_kssm .van-cell__label > div {
  font-size: 1.8rem;
  padding-bottom: 6rem;
}

/* .zxks_ksxx_kssm img {
  width: 2rem;
  height: 2rem;
}
.zxks_ksxx_kssm > div:nth-child(1) {
  width: 2rem;
  height: 2rem;
  padding: 0 0.5rem;
}
.zxks_ksxx_kssm > div:nth-child(2) {
  height: 2rem;
  line-height: 2rem;
  text-align: left;
}
.zxks_ksxx_kssm > div > div:nth-child(1) {
  text-align: left;
  font-size: 2rem;
  line-height: 2rem;
}
.zxks_ksxx_kssm > div > div:nth-child(2) {
  text-align: left;
  color: #999999;
  padding: 1rem 0 6rem 0;
  font-size: 1.8rem;
  line-height: 3rem;
} */
/* 按钮 */
.zxks_ksxx_btn {
  /* padding: 0 2.5rem; */
  height: 6rem;
  padding: 1rem 0;
  position: fixed;
  bottom: 0rem;
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  border-top: 0.1rem solid #e7e9ed;
}
.zxks_ksxx_btn /deep/ .van-button--info {
  padding: 2.5rem 10rem !important;
}
</style>
