<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        试卷详情
      </template>
    </van-nav-bar>

    <div class="paper-detail">
      <van-cell class="paper-name" title="2017年 中药学专业知识一 真题" >
        <template #label>
          <span>总分：120分</span>
          <span>时间：150分钟</span>
        </template>
      </van-cell>
      <div class="line-1rem"></div>
      <div  class="paper-text-type">
        <van-cell class="introduce">
          <template #title>
            <van-icon name="question-o" />
            <span>题型介绍</span>
          </template>
        </van-cell>
        <div class="line-01rem"></div>
        <van-cell class="text-type">
          <template #title>
            <span>最佳选择题</span>
            <span>（每题1分，共40题，共40分）</span>
          </template>
          <template #label>
            <span>下列每小题的五个选项中只有一项是最符合题意的正确答案，多选、错选或者不选均不得分。</span>
          </template>
        </van-cell>
        <van-cell class="text-type">
          <template #title>
            <span>配伍选择题</span>
            <span>（每题1分，共40题，共40分）</span>
          </template>
          <template #label>
            <span>下了题目分为若干组，每组题目对应同一组备选项，备选项可重复使用，也可不选用。每题只有1个备选项最符合题意</span>
          </template>
        </van-cell>
        <van-cell class="text-type">
          <template #title>
            <span>综合分析题</span>
            <span>（每题1分，共40题，共40分）</span>
          </template>
          <template #label>
            <span>下列每小题的五个选项中只有一项是最符合题意的正确答案，多选、错选或者不选均不得分。</span>
          </template>
        </van-cell>
        <van-cell class="text-type">
          <template #title>
            <span>多项选择题</span>
            <span>（每题1分，共40题，共40分）</span>
          </template>
          <template #label>
            <span>下列每小题的五个选项中只有一项是最符合题意的正确答案，多选、错选或者不选均不得分。</span>
          </template>
        </van-cell>
      </div>
      <div class="line-1rem"></div>
      <div class="abs">
        <van-image
          width="100%"
          height="100%"
          fit="fill"
          src="https://cdnoss.ksyt.com.cn/wxImages/2023sg.jpg"
        />
      </div>
    </div>
    <div class="paper-detail-bottom">
      <van-row>
        <van-col span="12" class="left" @click="toPractice">练习模式</van-col>
        <van-col span="12" class="right" @click="toPaper">考试模式</van-col>
      </van-row>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaperDetail',
  metaInfo: {
    title: '试卷详情', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {

    }
  },
  created() {
      
  },

  methods: {
    toPractice(){
      this.$router.push('/PaperPractice')
    },
    toPaper(){
      this.$router.push('/PaperExam')
    },

    onBack() {
      history.back();
    },

  },
  components: {

  },
  computed: {

  },
}
</script>
 
<style lang = "less">
#app {
  background-color: #ffffff;
}

.paper-detail {
  padding: 6.5rem 0rem 0.5rem 0rem;
}
.paper-detail .paper-name {
  padding:  1.5rem;
}
.paper-detail .paper-name .van-cell__title {
  line-height: 3rem;
  font-size: 2.2rem;
  font-weight: 600;
}
.paper-detail .paper-name .van-cell__label {
  line-height: 3rem;
  font-size: 1.8rem;
  font-weight: 500;
}
.paper-detail .paper-name .van-cell__label span {
  margin-right: 2rem;
  font-weight: 500;
}

.paper-detail .paper-text-type {
  padding: 0 1.5rem;
  border-radius: 1rem;
}
.paper-detail .paper-text-type .introduce  .van-cell__title {
  line-height: 6rem !important;
}
.paper-detail .paper-text-type .introduce  .van-cell__title .van-icon {
  padding-right: 0.5rem;
}
.paper-detail .paper-text-type .text-type {
  padding: 1.5rem 0;
}
.paper-detail .paper-text-type .text-type .van-cell__label {
  font-size: 1.9rem;
  line-height: 2.7rem !important;
  padding: 0.5rem 0 ;
}

.paper-detail-bottom {
  position: fixed;
  bottom: 0;
  height: 6rem;
  width: 100%;
}
.paper-detail-bottom .van-row {
  text-align: center;
  line-height: 6rem;
}
.paper-detail-bottom .van-row .left {
  background-color: #1989fa;
  color: #ffffff;
}
.paper-detail-bottom .van-row .right {
  background-color: #ff6034;
  color: #ffffff;
}
.abs .van-image .van-image__img {
  border-radius: 1rem;
}

</style>