<template>
  <div class="com_know">
    <div class="com_know_top">提示</div>
    <div class="com_know_title">{{tip}}</div>
    <div class="com_know_know">
      <van-button type="danger" @click="cancelTestTip">我知道了</van-button>
    </div>
  </div>
</template>
 
<script>
//import * from '@/*/*'
export default {
  components: {},
  props: ['tip'],
  data() {
    return {}
  },
  watch: {},
  created() {},
  methods: {
    cancelTestTip() {
      this.$emit('cancelTestTip')
    }
  }
}
</script>
 
<style scoped>
.com_know {
  overflow-y: hidden;
  height: 35vh;
}
.com_know_top {
  height: 6vh;
  line-height: 6vh;
  background: #ee0a24;
  color: #ffffff;
}
.com_know_title {
  height: 18vh;
  line-height: 18vh;
  font-weight: bold;
  font-size: 4.5vw;
  border-bottom: 1px solid #f3f1f1;
}
.com_know_know {
  height: 15vh;
}
.com_know_know /deep/ .van-button {
  width: 90%;
  border-radius: 10vw;
  margin-top: 4vw;
}
