import { render, staticRenderFns } from "./pageLoading.vue?vue&type=template&id=c916d638&scoped=true&"
import script from "./pageLoading.vue?vue&type=script&lang=js&"
export * from "./pageLoading.vue?vue&type=script&lang=js&"
import style0 from "./pageLoading.vue?vue&type=style&index=0&id=c916d638&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c916d638",
  null
  
)

export default component.exports