<template>
    <div>
        <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
            <template #title>
                看课记录
            </template>
        </van-nav-bar>
        <van-tabs v-model:active="active" class="courseList" color="#1989fa" @click="clickTab">
            <van-tab name="1" title="收费课程">
                    <van-card
                            v-for="(item,index) in learnRecord"
                            :key="index"
                            class="courseCard"
                            :title="item.title"
                            :thumb="item.photo"
                    >
                        <template #desc>
                            <div class="van-card__desc">
                                课程到期时间：
                            </div>
                        </template>
                        <template #tags>
                            <van-tag class="preLearn" plain round color="#646566" text-color="#646566">
                                上次学习：
                            </van-tag>
                        </template>
                        <template #footer>
                            <div style="margin-right: 10rem; height: 3rem;">
                                <van-progress
                                        percentage="60"
                                        pivot-text="已学习：60%"
                                        pivot-color="#888888"
                                        stroke-width="8"/>
                            </div>
                            <div style="position: absolute; width: 10rem;right: 1.5rem;top: 10.5rem;">
                                <van-button round size="mini" color="#1989fa" @click="enterLearn">进入学习</van-button>
                            </div>


                        </template>
                    </van-card>
                    <!-- 无课程的时候显示 -->
                    <!-- <van-empty
                            description="暂无报名课程或者课程已过期1"
                            image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                            image-size="24rem">
                        <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus">选课中心</van-button>
                    </van-empty> -->
            </van-tab>
            <van-tab name="2" title="免费课程">
                    <van-card
                            class="courseCard"
                            title="瓦尔挖二翁二玩儿翁"
                            thumb=""
                    >
                        <template #desc>
                            <div class="van-card__desc">
                                课程到期时间：
                            </div>
                        </template>
                        <template #tags>
                            <van-tag class="preLearn" plain round color="#646566" text-color="#646566">
                                上次学习：
                            </van-tag>
                        </template>
                        <template #footer>
                            <div style="margin-right: 10rem; height: 3rem;">
                                <van-progress
                                        percentage="15"
                                        pivot-text=" 已学习：15%"
                                        pivot-color="#888888"
                                        stroke-width="8"/>
                            </div>
                            <div style="position: absolute; width: 10rem;right: 1.5rem;top: 10.5rem;">
                                <van-button round size="mini" color="#1989fa" @click="enterLearn">进入学习</van-button>
                            </div>

                        </template>
                    </van-card>
                    <!-- <van-empty
                            description="暂无免费课程"
                            image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                            image-size="24rem">
                        <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus">选课中心</van-button>
                    </van-empty> -->
            </van-tab>
        </van-tabs>



    </div>
</template>

<script>
    import {courseList, GoLogin,onInfo} from '@/request/api'
    import {Toast} from "vant";

    export default {
        data() {
            return {
                avatarSrc: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/avatar.png",
                isShowModal: false,
                isShowLogin:'true',

                learnRecord: [
                    {
                        title: "我的课程(123123)",
                        show: true,
                        photo: "https://cdnoss.ksyt.com.cn/weixin/banci.png",
                        link: 'handleClickType'
                    },
                    {
                        title: "我的笔记(123123)",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/weixin/banci.png",
                        link: 'handleClickType'
                    },
                    {
                        title: "课程答疑（123123）",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/weixin/banci.png",
                        link: 'handleClickType'
                    }
                ],
            }
        },
        methods: {
            clickTab(){
                Toast('123');
            },
            clickFn(){
                if(!this.checkRadioFlag){
                    this.checked = '';
                }
                this.checkRadioFlag = false;
            },
            changeFn(){
                this.checkRadioFlag = true;
            },
            registerFn(){
                this.isShowLogin = !this.isShowLogin;
                this.isShowReg = !this.isShowReg;
            },
            enterLearn(){
                Toast('123');
            },
            onBack() {
            history.back();
            },
        },
    }
</script>

<style lang="less">
    .courseList {
        padding-bottom: 8rem;
    }

    .courseList .van-tabs__line {
        bottom: 0.5rem;
        left: 0;
        z-index: 1;
        width: 3.2rem;
        height: 0.5rem;
        border-radius: 2rem;
        position: absolute;
    }

    .courseList .van-tab--active .van-tab__text {
        font-size: 2rem;
    }
    .courseCard {
        padding: 1.5rem;
        color: #323233;
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    .courseCard .van-card__thumb {
        width: 12rem;
        height: 8rem;
        margin-right: 1rem;
    }

    .courseCard .van-card__thumb img {
        border-radius: 0.5rem;
    }

    .courseCard .van-card__title {
        max-height: 3rem;
        font-weight: 600;
        line-height: 2.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .courseCard .van-card__desc {
        max-height: 3rem;
        font-size: 1.6rem;
        line-height: 3rem;
    }

    .courseCard .van-card__content {
        min-height: 10rem;
    }

    .preLearn {
        width: 95%;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
        font-size: 1.2rem !important;
        border-color: #969799 !important;
    }

    .courseCard .van-card__footer {
        height: 18rem;
        padding-top: 0.5rem;
    }

    .courseCard .van-card__footer .van-progress__portion {
        background-color: #e7e9ed;
    }

    .courseCard .van-card__footer .van-progress__pivot {
        width: 12rem;
    }



</style>