<template>
  <div class="course-detail">
    <div v-html="content"></div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from "axios"


import { websiteInfo } from '@/request/api'
import {Toast} from "vant";

export default {
  // beforeRouteEnter(to, from, next) {
  //   let that = this;
  //   if (!to.params || !to.params.id) {
  //       next({
  //           path: from.name,
  //           query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由
  //       })
  //   }
  //   next();
  // },
  metaInfo() {
    return {
      title: this.title, //设置页面title
    }
  },
  data() {
    return {
      title: this.getBranchCompany(),
      content: '',
      branch: this.getBranch(),
      type: 1
    };
  },
  methods: {
    handleClickSearch() {
      this.$router.push('/course/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    onChange(index) {
      this.current = index;
    },
    handelService() {
      this.$router.push('/course/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelCart() {
      this.$router.push('/cart')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelCartAdd() {
      this.$router.push('/courseDetail/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelBuy() {
      // this.$router.push('/courseDetail/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      Toast('去咨询服务老师');
    },
    handelCommodityEvaluation() {
      this.$router.push('/commodityEvaluation')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },

    onBack() {
      history.back();
    },
    userKefu() {
      this.$router.push({ path: '/user' });
    },
    website() {
      let that = this;
      let branchId = that.branch;
      let type  = that.type;
      websiteInfo({branchId,type})
              .then(res => {
                if (res.data.code == 1) {
                  that.content = res.data.data.content;
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    },
    handleClickCourseDetail(id) {
      this.$router.push({
        name: 'CourseDetail',
        params: {
          id
        }
      })
    },
    copy(title) {
      this.$copyText(title).then(
              function(e) {
                Toast('复制成功')
              },
              function(e) {
                Toast('复制错误')
              }
      );
    }
  },
  created() {
    if(this.$route.params.type) {
      this.type = this.$route.params.type;
    }
    if(this.$route.params.branch) {
      this.branch = this.$route.params.branch;
    }
    this.title = this.getBranchCompany()
    this.website();
  },
  components: {

  },

}
</script>
<style lang="less">
@import '~@/assets/styles/common.less';

.course-detail {
  background-color: #ffffff;
  // overflow: hidden;
}

.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }
.shoplist1 {
  padding: 0.8rem;
  background-color: #ffffff;
}

.course-title {
  padding: 2.4rem 1.5rem 1.5rem 1.5rem;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: 600;
  color: #333;
}

.course-title .van-tag {
  padding: 0.3rem 0.6rem;
  position: relative;
  bottom: 0.2rem;
  margin-right: 0.6rem;
}

.course-title .course-price {
  padding-top: 0.5rem;
  color: #ee0a24;
  font-weight: 600;
  font-size: 2.4rem;
}

.course-service {
  padding: 0 1.5rem;
}

.course-service .van-tag {
  margin-right: 0.4rem;
}

.course-service .weapp-nav-icon {
  font-size: 3rem;
}

.service-popup {
  height: 50% !important;
  ;
}

.service-popup .content2 {
  padding: 1.2rem 0;
}

.service-popup .content2 .van-cell {
  padding: 0 1.5rem;
  margin: 2rem 0;
}

.service-popup .content2 .van-cell::after {
  border-width: 0;
}

.service-popup .content2 .van-cell .van-icon {
  font-size: 4rem;
  margin-right: 0.6rem;
}

.service-popup .content2 .van-cell .van-cell__title {
  font-size: 2rem;
}

.service-popup .content2 .van-cell .van-cell__label {
  font-size: 1.6rem;
}


.teacher-popup {
  height: 50% !important;
  ;
}

.teacher-popup .content2 {
  padding: 1.2rem 0;
}

.teacher-popup .content2 .van-cell {
  padding: 0 1.5rem;
  margin: 2rem 0;
}

.teacher-popup .content2 .van-cell::after {
  border-width: 0;
}

.teacher-popup .content2 .van-cell .van-icon {
  font-size: 4rem;
  margin-right: 0.6rem;
  color: #666666;
}

.teacher-popup .content2 .van-cell .van-cell__title {
  font-size: 2rem;
}

.teacher-popup .content2 .van-cell .van-cell__label {
  font-size: 2rem;
  line-height: 4rem;
}

.teacher-popup .content2 .pho-wx {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.shop_detail {
  background-color: #e7e9ed;
}

.shop_detail .van-tabs__content {
  padding-bottom: 6rem;
}

.shop_detail .courseList {
  margin: 1rem;
  border-radius: 2rem;
  background-color: #ffffff;
}

.shop_detail .courseList .courseListTitle {
  padding: 1.5rem 1rem;
  background-color: #ffffff;
  font-size: 2rem;
  line-height: 2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.shop_detail .courseList .courseListTitle {
  padding: 1.5rem 1rem;
  background-color: #ffffff;
  font-size: 2rem;
  line-height: 2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.shop_detail .courseList .van-cell {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 1rem;
}

.shop_detail .courseList .courseListTitle .van-tag {
  width: auto;
  padding: 0.4rem 1rem;
  line-height: 2rem;
  font-size: 1.8rem;
  justify-content: center;
}

.van-icon-like:before {
  color: #ee0a24 !important;
}

.shop_detail .evaluation {
  margin: 1rem;
  border-radius: 2rem;
  background-color: #ffffff;
}

.shop_detail .evaluation .van-cell {
  border-radius: 1rem;
  padding: 1.5rem 1rem;
}

.shop_detail .evaluation .van-cell .van-icon {
  float: left;
  top: 0;
  margin: 0.3rem 0.6rem;
}

.shop_detail .evaluation .van-cell .van-icon .van-icon__image {
  border-radius: 100%;
}

.shop_detail .evaluation .van-cell .van-cell__title {
  color: #333;
  font-size: 1.8rem;
}

.shop_detail .evaluation .van-cell .evaluationContent {
  color: #333;
  font-size: 1.8rem;
  line-height: 3.2rem;
}

.shop_detail .evaluation .title-1 {
  height: 4rem !important;
}

.shop_detail .evaluation .title-1 .van-cell__title {
  color: #333;
  font-size: 2.2rem;
  font-weight: 600;
  padding: 0 0.5rem;
}

.shop_detail .title-2 {
  height: 4rem !important;
}

.shop_detail .title-2 .van-cell__title {
  color: #333;
  font-size: 2.2rem;
  font-weight: 600;
  padding: 0 1rem !important;
}


.course-detail-title .van-nav-bar__content .van-icon {
  color: #ffffff;
}

.course-detail-title .van-nav-bar__content .van-nav-bar__left .van-icon {
  font-size: 3rem;
  padding: 0.5rem;
  border-radius: 5rem;
  background-color: #666666;
  opacity: 0.6;
  margin-left: 1rem;
  color: #ffffff;
}

.course-detail-title .van-nav-bar__content .van-nav-bar__right .van-icon {
  padding: 0.5rem;
  font-size: 3rem;
  border-radius: 5rem;
  background-color: #666666;
  opacity: 0.6;
  margin-right: 1rem;
  color: #ffffff;
}
</style>
