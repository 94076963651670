<template>
    <div class="comData">
        <!-- 在线答题(练习模式) -->
        <div class="comData_title" v-if="type=='0'">
            <div v-if="typeTest=='15'">
                <div class="stem">
                    <span class="data_value" v-html="obj.data_title"></span>
                    <!-- 显示图片 -->
                    <span v-for="(item,index) in obj.data_img" :key="index" v-show="item">
                          <van-image
                                  v-if="item"
                                  width="20rem"
                                  height="10rem"
                                  class="title_img"
                                  fit="cover"
                                  :src="item"
                                  @click="getImg(obj.data_img, index)"
                          />
                    </span>
                    <!-- 显示视频 -->
                    <span v-for="(item,index) in obj.data_video" :key="'video-'+index">
                          <video
                                  id="myVideo"
                                  class="video-js"
                                  controls
                                  preload="auto"
                                  :poster="obj.videoAnalysisPoster"
                                  :style="contentStyleObj1">
                              <source :src="item" type="video/mp4">
                          </video>
                    </span>
                </div>
                {{$store.state.currentIndex+1}}.
                <span style="margin-left: 0.2rem">
                    <span class="typeTest">(多选)</span>
                    <span v-html="obj.title"></span>
                </span>
                <div></div>
                <!-- 显示图片 -->
                <span v-for="(item,index) in obj.title_img" :key="index" v-show="item">
              <van-image
                      v-if="item"
                      width="20rem"
                      height="10rem"
                      class="title_img"
                      fit="cover"
                      :src="item"
                      @click="getImg(obj.title_img, index)"
              />
          </span>
                <span class="comData_scoreClass">（{{obj.score}}{{obj.questionState}}{{obj.isRight}}分）</span>
                <div v-if="obj.questionState == false">
                    <!-- 选项内容多选 -->
                    <div class="comCheckbox_option">
                        <div v-for="(item, index) in obj.tmList"
                             :key="index"
                             :class="item.select ? 'inputClassOk' : 'inputClass'"
                             @click="handleChange($event,item.key,index)"
                        >
                            <!--            <span v-if="(obj.correct_answer===obj.answer)" class="option_key_ok">{{item.key
                            }}</span>-->
                            <span class="option_key">{{item.key}}</span>
                            <span class="option_value">{{item.value}}</span>
                        </div>
                    </div>
                    <!-- 点击显示答案解析 -->
                    <div style="width:100%;text-align:center;padding:3rem 0">
                        <van-button round type="info" @click="clickShowAnalysis" style="width:20rem;">
                            {{obj.questionState==true?'隐藏解析':'查看解析'}}
                        </van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="comCheckbox_option">
                        <div v-for="(item, index) in obj.tmList" :key="index" class="inputClass">
                            <!-- 选择了正确答案  正确答案 绿色 正确答案未选择绿色 错误答案选择 红色 其他灰色-->

                            <!--           <span v-if="(obj.correct_answer===checkboxKey)" :class="checkboxKey==item.key?'option_key_ok':'option_key'">{{item.id == checkboxKey ? '' : item.key
                            }}</span>-->
                            <div v-if="(obj.isRight==true)">
                                <span class="option_key_ok">{{item.key}}</span>
                                <span class="option_value">{{item.value}}</span>
                            </div>
                            <div v-else-if="(obj.isRight==false)">
                                <span v-if="item.correct == true && item.active == true" class="option_key_ok"></span>
                                <span v-else-if="item.correct == true && item.active === false"
                                      class="option_key_no"></span>
                                <span v-else-if="item.correct == true && item.active === ''" class="option_key_ok"></span>
                                <span v-else-if="item.correct == false && item.select === true"
                                      class="option_key_no"></span>
                                <span v-else class="option_key">{{item.key}}</span>
                                <span class="option_value">{{item.value}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-show="obj.questionState">
                        <div class="comRadio_answer">
                            <div class="answer_box">
                                <span class="my_answer">参考答案：{{obj.correct_answer}}</span>
                                <span :class="obj.correct_answer===obj.answer?'reference_answer':'reference_answer_correct'">我的答案：{{(obj.answer ? obj.answer : '无')}}</span>
                            </div>
                            <div class="knowledge_box">
                                <span class="knowledge">考察知识点：</span>
                                <span class="knowledge-text">{{obj.knowledge}}</span>
                            </div>
                            <div class="accuracy_box">
                                <span class="accuracy">全网正确率：</span>
                                <span class="accuracy-text">{{obj.accuracy}}</span>
                                <van-button v-if="obj.videoAnalysis" round class="video-analysis" icon="play-circle-o"
                                            type="info" size="mini" @click="onShowVideo">{{obj.videoState?'隐藏视频':'视频讲解'}}
                                </van-button>
                            </div>
                        </div>
                        <!-- <div v-if="obj.videoAnalysis"> -->
                        <div class="comRadio_video_analysis" v-show="obj.videoState">
                            <van-cell center title="讲师视频讲解">
                            </van-cell>
                            <div class="video_box" ref="attrRef">
                                <video
                                        id="myVideo"
                                        class="video-js"
                                        controls
                                        preload="auto"
                                        :poster="obj.videoAnalysisPoster"
                                        :style="contentStyleObj1"
                                >
                                    <source :src="obj.videoAnalysis" type="video/mp4">
                                </video>
                            </div>
                        </div>
                    </div>
                    <div class="comRadio_analysis">
                        <van-cell center use-label-slot title="解析"/>
                        <van-tag color="#f6f7f9" text-color="#646566" size="large"><span v-html="obj.analysis"></span>
                        </van-tag>
                    </div>
                </div>
            </div>
        </div>


        <!-- 在线答题(考试模式) -->
        <div class="comData_title" v-if="type=='1'">
            <div v-if="typeTest=='15'">
                <div class="stem">
                    <span class="data_value">{{obj.data_title}}</span>
                    <!-- 显示图片 -->
                    <span v-for="(item,index) in obj.data_img" :key="index" v-show="item">
              <van-image
                      v-if="item"
                      width="20rem"
                      height="10rem"
                      class="title_img"
                      fit="cover"
                      :src="item"
                      @click="getImg(obj.data_img, index)"
              />
          </span>
                    <!-- 显示视频 -->
                    <span v-for="(item,index) in obj.data_video" :key="'video-'+index">
              <video
                      id="myVideo"
                      class="video-js"
                      controls
                      preload="auto"
                      :poster="obj.videoAnalysisPoster"
                      :style="contentStyleObj1"
              >
              <source :src="item" type="video/mp4">
              </video>
          </span>
                </div>
                {{$store.state.currentIndex+1}}.
                <span style="margin-left: 0.2rem">
          <span class="typeTest">(多选)</span>
          {{obj.title}}
        </span>
                <span class="comData_scoreClass">（{{obj.score}}分）</span>
                <!-- 选项内容多选 -->
                <div class="comCheckbox_option">
                    <div v-for="(item, index) in obj.tmList"
                         :key="index"
                         :class="item.select==true ? 'inputClassOk' : 'inputClass'"
                         @click="handleChange($event,item.key,index)"
                    >
                        <span class="option_key">{{item.key}}</span>
                        <span class="option_value">{{item.value}}</span>
                        <van-image
                            v-for="(item,index) in item.tmImg"
                            :key="index"
                            class="title_img"
                            fit="fill"
                            :src="item"
                        />
                    </div>
                </div>
            </div>
        </div>


        <!-- 测试结果(预览模式) -->
        <div class="comData_title" v-if="type=='2'">
            <div v-if="typeTest=='15'">
                <div class="stem">
                    <span v-html="obj.data_title" class="data_value"></span>
                    <!-- 显示图片 -->
                    <span v-for="(item,index) in obj.data_img" :key="index" v-show="item">
              <van-image
                      v-if="item"
                      width="20rem"
                      height="10rem"
                      class="title_img"
                      fit="cover"
                      :src="item"
                      @click="getImg(obj.data_img, index)"
              />
          </span>
                    <!-- 显示视频 -->
                    <span v-for="(item,index) in obj.data_video" :key="'video-'+index">
              <video
                      id="myVideo"
                      class="video-js"
                      controls
                      preload="auto"
                      :poster="obj.videoAnalysisPoster"
                      :style="contentStyleObj1"
              >
              <source :src="item" type="video/mp4">
              </video>
          </span>
                </div>
                {{$store.state.currentIndex+1}}.
                <span style="margin-left: 0.2rem">
          <span class="typeTest">(多选)</span>
          {{obj.title}}
        </span>
                <span class="comData_scoreClass">（{{obj.score}}分）</span>
                <!-- 选项内容多选 -->
                <div class="comCheckbox_option">
                    <div v-for="(item, index) in obj.tmList"
                         :key="index"
                         :class="item.select ? 'inputClassOk' : 'inputClass'"
                         @click="handleChange($event,item.key,index)"
                    >
                        <span class="option_key">{{item.key}}</span>
                        <span class="option_value">{{item.value}}</span>
                        <van-image
                            v-for="(item,index) in item.tmImg"
                            :key="index"
                            class="title_img"
                            fit="fill"
                            :src="item"
                        />
                    </div>
                </div>
                <!-- 答案解析 -->
                <div class="comRadio_answer">
                    <div class="answer_box">
                        <span class="my_answer">参考答案：{{obj.correct_answer}}</span>
                        <span class="reference_answer">我的答案：{{obj.answer ? obj.answer : '无'}}</span>
                    </div>
                    <div class="knowledge_box">
                        <span class="knowledge">考察知识点：</span>
                        <span class="knowledge-text">{{obj.knowledge}}</span>
                    </div>
                    <div class="accuracy_box">
                        <span class="accuracy">全网正确率：</span>
                        <span class="accuracy-text">{{obj.accuracy}}</span>
                        <van-button round class="video-analysis" icon="play-circle-o" type="info" size="mini"
                                    @click="onShowVideo">{{obj.videoState?'隐藏视频':'视频讲解'}}
                        </van-button>
                    </div>
                </div>
                <div class="comRadio_video_analysis" v-show="obj.videoState">
                    <van-cell center title="讲师视频讲解">
                    </van-cell>
                    <div class="video_box" ref="attrRef1">
                        <video
                                id="myVideo"
                                class="video-js"
                                controls
                                preload="auto"
                                :poster="obj.videoAnalysisPoster"
                                :style="contentStyleObj1"
                        >
                            <source :src="obj.videoAnalysis" type="video/mp4">
                        </video>
                    </div>
                </div>
                <div class="comRadio_analysis">
                    <van-cell center use-label-slot title="解析">
                        <!-- <template #right-icon>
                            <van-button round center type="warning" size="mini" icon="question-o" @click="onShowShare">我要提问</van-button>
                        </template> -->
                    </van-cell>
                    <van-tag color="#f6f7f9" text-color="#646566" size="large"><span v-html="obj.analysis"></span>
                    </van-tag>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import {ImagePreview} from 'vant';

    export default {
        components: {ImagePreview},
        props: ['obj', 'type', 'typeTest', 'answerStatus'],
        data() {
            return {

                // 选中的答案
                checkboxList: [],
                // 选项
                tmList: [],
                showVideo: true,
                contentStyleObj1: {
                    height: '',
                    width: ''
                },
            }
        },
        mounted() {
            this.contentStyleObj1.height = this.$refs.attrRef1.offsetWidth * 9 / 16 + 'px ' + '!important';
            this.contentStyleObj1.width = window.getComputedStyle(this.$refs.attrRef1).width + ' ' + '!important';

        },
        watch: {

            obj: {
                handler(val) {
                    if (!val.answer) {
                        this.checkboxList = []
                    } else {
                        if (typeof val.answer === 'string') {
                            this.checkboxList = this.strAndArr(val.answer)
                        }
                        if (typeof val.answer === 'object') {
                            this.checkboxList = val.answer
                        }
                    }

                },
                deep: true,
                immediate: true
            }

        },
        created() {
        },
        methods: {

            getImg(images, index) {
                // console.log(images)
                console.log(images)
                ImagePreview({
                    images: images, //需要预览的图片 URL 数组
                    showIndex: true, //是否显示页码
                    loop: false, //是否开启循环播放
                    startPosition: index //图片预览起始位置索引
                })
            },
            onShowVideo() {
                this.obj.videoState = !this.obj.videoState;
                this.showVideo = !this.showVideo
            },
            // 字符串转数组
            strAndArr(str) {
                return str.split(',')
            },
            // 选中试题
            changeFun(val) {
                val.state = !val.state
            },
            /**
             * 多选
             * 判断 选中、删除
             */
            //实时获取选中的选项id，注意此方法中e参数的传入
            handleChange(e, key, index) {
                if (this.answerStatus == true) {
                    return false;
                }
                // console.log(e);
                // console.log(key);
                const currentTest = this.$store.state.zxksList[this.$store.state.currentIndex]
                console.log(currentTest);
                let str = currentTest.tmList[index].key;
                //判断是选中、删除
                if (currentTest.tmList[index].select == true) {
                    console.log('删除');
                    //删除选项
                    currentTest.tmList[index].select = false;
                    currentTest.tmList[index].active = '';
                    currentTest.answer = currentTest.answer.replace(str, '');
                } else {
                    //添加
                    console.log('添加');
                    let selectedAnswer = currentTest.answer;
                    selectedAnswer = `${selectedAnswer}${str}`
                    currentTest.answer = selectedAnswer.split('').sort().join('');
                    currentTest.tmList[index].select = true;
                }

                const rightAnswerArr = currentTest.correct_answer.split('').sort() //正确的答案
                const selectedAnswerArr = currentTest.answer.split('').sort(); //选择的答案
                if (this.arrEuqalse(selectedAnswerArr, rightAnswerArr)) {
                    currentTest.isRight = true
                } else {
                    currentTest.isRight = false
                }
                currentTest.tmList.forEach(item => {
                    for (let char of rightAnswerArr) {
                        if (item.key == char) {
                            item.active = true;
                        }
                    }
                })
                this.$store.state.zxksList[this.$store.state.currentIndex] = currentTest;
                this.obj = currentTest;


                // if(e){
                //   this.checkedData.push(key);
                // }else{
                //   this.delete(id);
                // }
                // this.checkedDataArr = this.unique3(this.checkedData).sort()
                // console.log('选择的答案'+this.checkedDataArr)
                // if(str.indexOf(“World”) != -1){

                // }
            },
            // 数组对比是否相等
            arrEuqalse(arr1, arr2) {
                if (!arr1 || !arr2) {
                    return false;
                }
                if (arr1.length != arr2.length) {
                    return false;
                }
                for (var i = 0; i < arr2.length; i++) {
                    if (arr1.indexOf(arr2[i]) == -1) {
                        return false;
                    }
                }
                for (var i = 0; i < arr1.length; i++) {
                    if (arr2.indexOf(arr1[i]) == -1) {
                        return false;
                    }
                }
                return true;
            },
//显示解析
            clickShowAnalysis() {
                // const currentTest = this.$store.state.zxksList[this.$store.state.currentIndex];
                if (this.obj.questionState == true) {
                    return false;
                }
                this.obj.questionState = !this.obj.questionState;

                this.showAnalysis = !this.showAnalysis
                this.checkedDataStr = this.checkedDataArr.join('')
                this.aa = this.arrEuqalse(this.correctList, this.checkedDataArr)
                console.log(this.correctList + '判断' + this.checkedDataStr)
                console.log(this.aa)
                this.checkedDataArr.forEach((item, index) => {
                    console.log(item + '数组中的每一项')
                    console.log(index + '数组中的下标')

                });
                console.log(this.checkedData + '**复现已选答案**');
            },
        }
    }
</script>

<style scoped>
    .comData_title {
        width: auto;
        text-align: left;
        padding: 0 1rem;
        font-size: 2.2rem;
        line-height: 3.2rem;
        /* overflow-y: scroll;
        touch-action: pan-y !important; */
    }

    .comData_scoreClass {
        color: #a0a0a0;
    }

    .comData_text {
        margin: 1rem;
        border: 0.1rem solid gainsboro;
        background: transparent;
        border-radius: 1rem;
    }

    .comData_title .stem {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
    }

    /* 处理单选样式 */
    .comData_title .comRadio_option input {
        width: 2rem;
        height: 2rem;
    }

    .comData_title .comRadio_option {
        margin: 1rem 0rem 2rem 0rem;
    }

    .comData_title .inputClass {
        position: relative;
        height: auto;
        padding: 1rem;
        /* margin-left: 15px; */
        text-align: left;
        margin-top: 1.5rem;
        background: #f6f7f9;
        border: 0.1rem solid #f6f7f9;
        border-radius: 0.5rem;
        font-size: 2rem;
        line-height: 3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .comData_title .inputClass .option_key {
        border: 0.1rem solid #cccccc;
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
        padding: 0.4rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comData_title .inputClass .option_key_ok {
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/icon-correct.png');
        background-size: 100%;
        color: #07c160;
        border-radius: 100%;
        height: 2.2rem;
        width: 2.2rem;
        padding: 0.5rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comData_title .inputClass .option_key_no {
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/icon-error.png');
        background-size: 100%;
        color: #ee0a24;
        border-radius: 100%;
        height: 2.2rem;
        width: 2.2rem;
        padding: 0.5rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comData_title .inputClass .option_value {
        padding-left: 4rem;
        display: flex;
        flex-direction: column;
    }

    .comData_title .inputClassOk {
        position: relative;
        height: auto;
        padding: 1rem;
        text-align: left;
        margin-top: 1.5rem;
        background: #f6f7f9;
        border: 0.1rem solid #f6f7f9;
        color: #1989fa;
        border-radius: 0.5rem;
        font-size: 2rem;
        line-height: 3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .comData_title .inputClassOk .option_key_ok {
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/icon-correct.png');
        background-size: 100%;
        color: #07c160;
        border-radius: 100%;
        height: 2.2rem;
        width: 2.2rem;
        padding: 0.5rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comData_title .inputClassOk .option_key_no {
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/icon-error.png');
        background-size: 100%;
        color: #ee0a24;
        border-radius: 100%;
        height: 2.2rem;
        width: 2.2rem;
        padding: 0.5rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comData_title .inputClassOk .option_key {
        border: 0.1rem solid #1989fa;
        background-color: #1989fa;
        color: #ffffff;
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
        padding: 0.4rem;
        position: absolute;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */
    }

    .comData_title .inputClassOk .option_value {
        padding-left: 4rem;
        display: flex;
        justify-content: flex-start;
    }

    .comData_title .inputClass input[type='radio'] {
        width: 2rem;
        height: 2rem;
        opacity: 0;
    }

    .typeTest {
        color: #4ba5ff;
    }


</style>
