<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        {{service.branch}}
      </template>
    </van-nav-bar>
    <div style="padding: 6rem 0 22rem 0;">
      <van-form @failed="onFailed" class="school">
        <van-cell-group inset >
          <van-field
            v-model="value1"
            name="name"
            colon
            disabled
            label="服务老师"
            placeholder=""
            
          >
          <template #input>
            {{service.real_name}}
          </template>
          </van-field>
          <van-field
            v-model="value2"
            name="tel"
            colon
            disabled
            label="手机号码"
            placeholder=""
          
            >
          <template #input>
            {{service.phone}}
          </template>
          </van-field>
          <van-field
            v-model="value3"
            name="wxNum"
            colon
            disabled
            label="微信号"
            placeholder=""
          
            >
          <template #input>
            {{service.we_chat_code}}
          </template>
<!--          <template #button>-->
<!--            <van-button size="small" type="info" @click="doCopy()">复制</van-button>-->
<!--          </template>-->
          </van-field>
        </van-cell-group>
        <div class="wxImg">
          <van-image
            width="30rem"
            height="30rem"
            fit="cover"
            :src="service.code"
          />
        </div>
      </van-form>



    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { informationDetail } from '@/request/api'

// import Tips from '@/components/Tips'
export default {
  name: 'Cart',
  metaInfo: {
    title: '我的校区', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      message:'15233667113aa',
      service: []
    }
  },

  methods: {
    onBack() {
      history.back();
    },
    dataProcessing(val){
      this.message = this.message
    },
    doCopy:function(val){
      this.dataProcessing(val)
      this.$copyText(this.message).then(function(e){
        Toast('内容已复制')
      },function(e){
        Toast('该内容不能复制')
        console.log(e)
      })
    },
    getSchool() {
      let that = this;
      informationDetail()
              .then(res => {
                if (res.data.code == 1) {
                  that.service = res.data.data.data;
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    }
  },
  created() {
    console.log(123);
    this.getSchool();

  }
}
</script>
 
<style lang = "less" scoped>
.school {
  padding: 1rem 1.5rem;
  .van-cell-group--inset {
    border-radius: 1rem;
  }
  .van-field{
    font-size: 2rem;
    line-height: 5rem;
    padding: 1rem 1.5rem;
  }
  .wxImg {
    padding: 2rem;
    text-align: center;
  }
}

</style>