<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        模考试卷
      </template>
    </van-nav-bar>
    <div class="maintext">
      <keep-alive>
        <!-- 单选题 -->
        <QuestionSingleChoice
          ref="radioRef"
          :dataObj="theCurrentValue"
          v-if="topicType =='S'"
          @getvalue="getvalueEv"
        ></QuestionSingleChoice>

        <!-- 多选题 -->
        <QuestionMultipleChoice
          ref="multipeChoiceRef"
          :dataObj="theCurrentValue"
          v-if="topicType =='M'"
          @getMultvalue="getMultvalue"
          @getotherVal="getotherVal"
        ></QuestionMultipleChoice>

        <!-- 简答题 -->
        <!-- <QuestionShortAnswer
          ref="multipeChoiceRef"
          :dataObj="theCurrentValue"
          v-if="topicType =='M'"
          @getMultvalue="getMultvalue"
          @getotherVal="getotherVal"
        ></QuestionShortAnswer> -->

      </keep-alive>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      result: [],
    }
  },
  methods: {
    toPractice() {
      this.$router.push('/PaperPractice')
    },

    onBack() {
      history.back();
    },

  },
  mounted() {
    let result = [];
    if (!this.questionList.length) {
      result = [{}];
    } else {
      for (let i = 0; i < this.questionList.length; i++) {
        if (this.questionList[i].type === "radio") {
          result.push({ index: i + 1, value: "" });
        } else {
          result.push({ index: i + 1, value: [] });
        }
      }
    }
    this.result = result;
  },
  components: {
    QuestionSingleChoice,
    QuestionMultipleChoice,
    QuestionShortAnswer,
  },
  computed: {

  }

}
</script>
 
<style lang = "less">
#app {
  background-color: #ffffff;
}

.maintext {
  padding: 7rem 1.5rem 0.5rem 1.5rem;
}
.subject .question {
  font-size: 2rem;
  line-height: 3rem;
}
.subject .van-radio-group {
  padding: 2rem 1rem;
  font-size: 2rem !important;
  line-height: 3rem;
}
.subject .van-radio {
  padding-bottom: 2rem;
}

</style>