<!-- 
  author：
  page：提示  (取消  确认)
-->
<template>
  <div class="com_ok_cancel">
    <div class="com_ok_cancel_top">提示</div>
    <div class="com_ok_cancel_title">{{ tip }}</div>
    <div class="com_ok_cancel_know">
      <div>
        <van-button type="default" @click="Fun('0')" :disabled="submitDisabled">{{ tipLeft }}</van-button>
      </div>
      <div>
        <van-button
          type="danger"
          @click="Fun('1')"
          :disabled="submitDisabled"
          >{{ tipRight }}</van-button
        >
      </div>
    </div>
  </div>
</template>
 
<script>
//import * from '@/*/*'
export default {
  components: {},
  props: ['tip', 'tipLeft', 'tipRight'],
  data () {
    return {
      submitDisabled: false
    }
  },
  watch: {},
  created () { },
  methods: {
    // 点击（确定、取消）
    Fun (val) {
      // val  0：取消   1：确定
      this.$emit('cancelTestSubmit', val)
    }
  }
}
</script>
 
<style scoped>
.com_ok_cancel {
  overflow-y: hidden;
}
.com_ok_cancel_top {
  height: 6vh;
  line-height: 6vh;
  background: #eb3132;
  color: #ffffff;
}
.com_ok_cancel_title {
  height: 12vh;
  line-height: 5vh;
  font-weight: bold;
  font-size: 4.5vw;
  padding-top: vh;
  margin-top: 6vh;
  width: 60vw;
  margin-left: 5vw;
}
.com_ok_cancel_know {
  height: 11vh;
  display: flex;
}
.com_ok_cancel_know > div {
  width: 40%;
  height: 11vh;
  margin-left: 5vw;
}
.com_ok_cancel_know /deep/ .van-button {
  width: 100%;
  margin-top: 3.5vh;
}
</style>
