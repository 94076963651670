<template>
  <div>
    <van-nav-bar class="courseData-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        领取资料
      </template>
    </van-nav-bar>
    <div style="padding: 6rem 0 1rem 0;">
      <van-tabs class="questionList" color="#1989fa" swipeable sticky>
<!--        <van-tab title="全部资料">-->
<!--          <div style="padding-top: 0.5rem;">-->
<!--                <div class="courseData" @click="questionDetail">-->
<!--                    <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">PDF</van-tag>-->
<!--                    <van-cell center class="answering">-->
<!--                        <template #icon>-->
<!--                            &lt;!&ndash; <van-icon name="../assets/icons/doc.png" /> &ndash;&gt;-->
<!--                            <img class="icon-img" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/pdf.png" alt />-->
<!--                        </template>-->
<!--                        <template #title>-->
<!--                            <span class="custom-title">中药一《考前冲刺必看》</span>-->
<!--                        </template>-->
<!--                        <template #label>-->
<!--                            <span>高频考点，真题考点，如何快速学好中药一这门学科，轻松拿分！</span>-->
<!--                        </template>-->
<!--                    </van-cell>-->
<!--                    <div class="reply_tag">-->
<!--                        <van-tag round type="warning" >所需：0积分</van-tag>-->
<!--                        <van-tag round type="primary" style="float: right;">免费领取</van-tag>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="courseData" @click="questionDetail">-->
<!--                    <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">PDF</van-tag>-->
<!--                    <van-cell center class="answering">-->
<!--                        <template #icon>-->
<!--                            &lt;!&ndash; <van-icon name="../assets/icons/doc.png" /> &ndash;&gt;-->
<!--                            <img class="icon-img" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/pdf.png" alt />-->
<!--                        </template>-->
<!--                        <template #title>-->
<!--                            <span class="custom-title">2022年《中药一基础班课程讲义》</span>-->
<!--                        </template>-->
<!--                        <template #label>-->
<!--                            <span>高频考点，真题考点，如何快速学好中药一这门学科，轻松拿分！</span>-->
<!--                        </template>-->
<!--                    </van-cell>-->
<!--                    <div class="reply_tag">-->
<!--                        <van-tag round type="warning" >所需：0积分</van-tag>-->
<!--                        <van-tag round type="danger" style="float: right;">购课领取</van-tag>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="courseData" @click="questionDetail">-->
<!--                    <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">PDF</van-tag>-->
<!--                    <van-cell center class="answering">-->
<!--                        <template #icon>-->
<!--                            &lt;!&ndash; <van-icon name="../assets/icons/doc.png" /> &ndash;&gt;-->
<!--                            <img class="icon-img" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/pdf.png" alt />-->
<!--                        </template>-->
<!--                        <template #title>-->
<!--                            <span class="custom-title">2022年《中药二基础班课程讲义》</span>-->
<!--                        </template>-->
<!--                        <template #label>-->
<!--                            <span>高频考点，真题考点，如何快速学好中药一这门学科，轻松拿分！</span>-->
<!--                        </template>-->
<!--                    </van-cell>-->
<!--                    <div class="reply_tag">-->
<!--                        <van-tag round type="warning" >所需：0积分</van-tag>-->
<!--                        <van-tag round type="danger" style="float: right;">购课领取</van-tag>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="courseData" @click="questionDetail">-->
<!--                    <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">PDF</van-tag>-->
<!--                    <van-cell center class="answering">-->
<!--                        <template #icon>-->
<!--                            &lt;!&ndash; <van-icon name="../assets/icons/doc.png" /> &ndash;&gt;-->
<!--                            <img class="icon-img" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/pdf.png" alt />-->
<!--                        </template>-->
<!--                        <template #title>-->
<!--                            <span class="custom-title">2022年《中药综合基础班课程讲义》</span>-->
<!--                        </template>-->
<!--                        <template #label>-->
<!--                            <span>高频考点，真题考点，如何快速学好中药一这门学科，轻松拿分！</span>-->
<!--                        </template>-->
<!--                    </van-cell>-->
<!--                    <div class="reply_tag">-->
<!--                        <van-tag round type="warning" >所需：0积分</van-tag>-->
<!--                        <van-tag round type="danger" style="float: right;">购课领取</van-tag>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="courseData" @click="questionDetail">-->
<!--                    <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">PDF</van-tag>-->
<!--                    <van-cell center class="answering">-->
<!--                        <template #icon>-->
<!--                            &lt;!&ndash; <van-icon name="../assets/icons/doc.png" /> &ndash;&gt;-->
<!--                            <img class="icon-img" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/pdf.png" alt />-->
<!--                        </template>-->
<!--                        <template #title>-->
<!--                            <span class="custom-title">2022年《法规基础班课程讲义》</span>-->
<!--                        </template>-->
<!--                        <template #label>-->
<!--                            <span>高频考点，真题考点，如何快速学好中药一这门学科，轻松拿分！</span>-->
<!--                        </template>-->
<!--                    </van-cell>-->
<!--                    <div class="reply_tag">-->
<!--                        <van-tag round type="warning" >所需：0积分</van-tag>-->
<!--                        <van-tag round type="danger" style="float: right;">购课领取</van-tag>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="courseData" @click="questionDetail">-->
<!--                    <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">Word</van-tag>-->
<!--                    <van-cell center class="answering">-->
<!--                        <template #icon>-->
<!--                            &lt;!&ndash; <van-icon name="../assets/icons/doc.png" /> &ndash;&gt;-->
<!--                            <img class="icon-img" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/word.png" alt />-->
<!--                        </template>-->
<!--                        <template #title>-->
<!--                            <span class="custom-title">2022年《中药一基础班课程讲义》</span>-->
<!--                        </template>-->
<!--                        <template #label>-->
<!--                            <span>高频考点，真题考点，如何快速学好中药一这门学科，轻松拿分！</span>-->
<!--                        </template>-->
<!--                    </van-cell>-->
<!--                    <div class="reply_tag">-->
<!--                        <van-tag round type="warning" >所需：0积分</van-tag>-->
<!--                        <van-tag round type="primary" style="float: right;">点击领取</van-tag>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="courseData" @click="questionDetail">-->
<!--                    <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">ZIP</van-tag>-->
<!--                    <van-cell center class="answering">-->
<!--                        <template #icon>-->
<!--                            &lt;!&ndash; <van-icon name="../assets/icons/doc.png" /> &ndash;&gt;-->
<!--                            <img class="icon-img" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/zip.png" alt />-->
<!--                        </template>-->
<!--                        <template #title>-->
<!--                            <span class="custom-title">中药一《考前冲刺必看》</span>-->
<!--                        </template>-->
<!--                        <template #label>-->
<!--                            <span>高频考点，真题考点，如何快速学好中药一这门学科，轻松拿分！</span>-->
<!--                        </template>-->
<!--                    </van-cell>-->
<!--                    <div class="reply_tag">-->
<!--                        <van-tag round type="warning" >所需：0积分</van-tag>-->
<!--                        <van-tag round type="primary" style="float: right;">点击领取</van-tag>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="courseData" @click="questionDetail">-->
<!--                    <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">Link</van-tag>-->
<!--                    <van-cell center class="answering">-->
<!--                        <template #icon>-->
<!--                            &lt;!&ndash; <van-icon name="../assets/icons/doc.png" /> &ndash;&gt;-->
<!--                            <img class="icon-img" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/link.png" alt />-->
<!--                        </template>-->
<!--                        <template #title>-->
<!--                            <span class="custom-title">中药一《考前冲刺必看》</span>-->
<!--                        </template>-->
<!--                        <template #label>-->
<!--                            <span>高频考点，真题考点，如何快速学好中药一这门学科，轻松拿分！</span>-->
<!--                        </template>-->
<!--                    </van-cell>-->
<!--                    <div class="reply_tag">-->
<!--                        <van-tag round type="warning" >所需：0积分</van-tag>-->
<!--                        <van-tag round type="primary" style="float: right;">点击领取</van-tag>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; <van-empty image-size="30rem" description="电子资料暂未更新"/> &ndash;&gt;-->
<!--          </div>-->
<!--        </van-tab>-->
        <van-tab title="我的资料">
        <div style="padding-top: 0.5rem;">
                <div class="courseData" @click="questionDetail(item.id)" v-for="(item,index) in dataList">
                    <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">{{item.typeStr}}</van-tag>
                    <van-cell center class="answering" @click="showDownload(item.id,item.status)">
                        <template #icon>
                            <!-- <van-icon name="../assets/icons/doc.png" /> -->
                            <img class="icon-img" v-if="item.type ==1" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/pdf.png" alt />
                            <img class="icon-img" v-else-if="item.type ==2" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/word.png" alt />
                            <img class="icon-img" v-else-if="item.type ==3" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/zip.png" alt />
                            <img class="icon-img" v-else-if="item.type ==4" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/link.png" alt />
                            <img class="icon-img" v-else-if="item.type ==5" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/ppt.png" alt />
                            <img class="icon-img" v-else-if="item.type ==6" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/photo.png" alt />
                            <img class="icon-img" v-else-if="item.type ==7" src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/txt.png" alt />
                            <img class="icon-img" v-else src="https://cdnoss.ksyt.com.cn/wxImages/assets/icons/unknown.png" alt />
                        </template>
                        <template #title>
                            <span class="custom-title">{{item.title}}</span>
                        </template>
                        <template #label>
                            <span>{{item.desc}}</span>
                        </template>
                        <template #right-icon>
                            <van-icon name="back-top" color="#1989fa"  />
                        </template>
                    </van-cell>
                </div>
          
            <van-empty image-size="30rem" description="电子资料暂未更新"/>
        </div>

        </van-tab>
      </van-tabs>
      <van-popup
        class="downloadPop"
        v-model="show"
        position="bottom"
        :style="{ height: '70%' }"
      >
      <van-cell center class="downTitle">
          <template #title>
              <span class="custom-title" style="padding-left:1rem;font-weight: 600;font-size: 2.4rem;">文件列表</span>
          </template>
          <template #right-icon>
            <van-icon name="close" @click="showDownload1" />
          </template>
      </van-cell>
      <div class="dataList11">
        <van-cell center v-for="(item,index) in dataUrlList" :key="index" @click="srcBtn(item.url,item.title)">
            <template #title>
                <span class="custom-title">{{ item.title }}</span>
            </template>
            <template #icon>
              <van-icon name="orders-o" />
            </template>
            <template #right-icon>
              <van-icon name="back-top" color="#1989fa"  />
            </template>
        </van-cell>
        <van-divider dashed style="margin:2rem 0;font-size: 2rem;">已经到底啦~</van-divider>
        
      </div>
      </van-popup>

    </div>
  </div>
</template>

<script>

// import Tips from '@/components/Tips'
import {
    courseDataLogin,
    courseData,
    courseDataUrlList, onShareUrl
} from '@/request/api'
import {Toast} from "vant";
import wx from "weixin-js-sdk";
export default {
  name: 'CourseData',
  metaInfo: {
    title: '领取资料', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // active:'',
      show:false,
        dataList:[],
        dataUrlList:[],
        commodityId:'',
        courseId:''
    }
  },
  created() {
      this.commodityId = this.$route.query.commodityId
      this.courseId = this.$route.query.courseId
      this.getData()
      this.onWxConfig();
  },

  methods: {
      getData() {
          let groupId = this.getGroupId();
          let subjectId = this.getSubjectId();
          let commodity_id = this.commodityId;
          let course_id = this.courseId;
          let that = this;
          if(this.getToken()) {
              courseData({commodity_id,course_id})
                  .then(res => {
                      if(res.data.code == 1) {
                          that.dataList = res.data.data.data;
                      }
                  })
                  .catch(err => {
                      console.log("error");
                      console.log(err);
                  })
          } else {
              courseDataLogin({groupId,subjectId})
                  .then(res => {
                      if(res.data.code == 1) {
                          that.dataList = res.data.data.data;
                      }
                  })
                  .catch(err => {
                      console.log("error");
                      console.log(err);
                  })
          }
      },
    questionDetail(id) {
          console.log(id);
      // this.$router.push('/CourseQuestionDetail')
      // window.open('https://cdnoss.ksyt.com.cn/wxPDF/zhongyi-kaodian.pdf', '_blank');
    },
    showDownload(id,status){
          if(!status) {
              Toast.fail('请购买之后下载');
              return false;
          }
        let that = this;
        courseDataUrlList({id})
            .then(res => {
                if(res.data.code == 1) {
                    that.dataUrlList = res.data.data.data;
                }
            })
            .catch(err => {
                console.log("error");
                console.log(err);
            })
        this.show = true;
    },
    showDownload1(){
      this.show = false;
    },

    onBack() {
        if(window.android != undefined) {
            window.android.back();
        }
        if (window.history.length <= 1) {
            this.$router.push({path:'/'})
            return false
        } else {
            this.$router.go(-1)
        }
      // history.back();
    },
      onWxConfig() {
          let that = this;
          let branchId = that.getBranch();
          // alert(location.href.split('#')[0]);
          // alert(window.location.origin);
          onShareUrl({url: location.href.split('#')[0],branchId})
              .then(res => {
                  if (res.data.code == 1) {
                      wx.config({
                          debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                          appId: res.data.data.sdk.appId,         // 必填，公众号的唯一标识，填自己的！
                          timestamp: res.data.data.sdk.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                          nonceStr: res.data.data.sdk.nonceStr,   // 必填，生成签名的随机串
                          signature: res.data.data.sdk.signature, // 必填，签名，见附录1
                          jsApiList: [
                              'checkJsApi',
                              'onMenuShareQQ',
                              'onMenuShareWeibo',
                              'onMenuShareQZone',
                              'updateAppMessageShareData',
                              'updateTimelineShareData',
                              'onMenuShareTimeline',
                              'onMenuShareAppMessage',
                              'wx-open-launch-weapp'
                          ],
                          openTagList: ['wx-open-launch-weapp']
                      })
                      let shareConfig = {
                          title:  res.data.data.share.fx_share_title,
                          desc: res.data.data.share.fx_share_desc,
                          link:  that.getUrlParameter(),
                          imgUrl: res.data.data.share.fx_share_logo,
                          type: '',
                          dataUrl: '',
                          success: function (r) {
                              console.log("分享成功"+JSON.stringify(rr));
                          },
                          cancel: function (rr) {
                              console.log('分享错误'+JSON.stringify(rr));
                          }
                      }
                      that.getIcoUrl(res.data.data.share.ico);
                      wx.ready(function () {
// that.getHtml();
                          //分享到朋友圈
                          wx.onMenuShareTimeline(shareConfig);
                          //分享给朋友
                          wx.onMenuShareAppMessage(shareConfig);
                          wx.onMenuShareQQ(shareConfig); // QQ
                          wx.updateAppMessageShareData(shareConfig);
                          wx.updateTimelineShareData(shareConfig);
                      });
                      wx.error(function(res){
                          console.log(123);
                          console.log(res);
                          // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                      })
                  }
              })
              .catch(err => {
                  console.log("error");
                  console.log(err);
              })
      },
    srcBtn(src,title){
          if(!src) {
              return false;
          }
        if(window.android != undefined) {
            window.android.openurl(src)
        } else {
            window.open(src, '_blank');
            // var link = document.createElement('a')
            // link.href = window.URL.createObjectURL(src)
            // link.download = title
            // link.click()
            // //释放内存
            // window.URL.revokeObjectURL(link.href)
            // let a = document.createElement('a')
            // a.href =src
            // a.click();
        }
    }
  },
}
</script>
 
<style lang = "less" >
    .courseData-title{
        z-index: 9;
    }
    .courseData-title .van-icon-arrow-left {
        color: #323233 !important;
    }
    .questionList .van-sticky--fixed {
        top: 5.9rem !important;
    }
    .questionList .courseData {
        margin: 0.5rem 1rem 1rem 1rem;
        border-radius: 1rem;
        background-color: #ffffff;
        padding-bottom: 1.5rem;
    }
    .questionList .courseData .van-cell {
        border-radius: 1rem !important;
        padding: 2.5rem 1.5rem 1.5rem .5rem;
    }
    .questionList .courseData .van-cell::after {
        border-width: 0 !important;
    }

    .questionList .courseData .answering .custom-title{
        // font-size: 1.8rem;
        font-weight: 600;
        padding-right: 1rem;
        line-height: 4rem;
    }
    .questionList .courseData .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }
    .questionList .courseData .answering .van-cell__label {
        color: #323233;
        font-size: 1.8rem;
        line-height: 2.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;  //使用自适应布局
        -webkit-line-clamp: 2;  //设置超出行数，要设置超出几行显示省略号就把这里改成几
        -webkit-box-orient: vertical;
    }
    .questionList .courseData .project_tag{
      position: absolute;
      left: 1rem;
      // float: left;
      padding: 0.3rem 0.8rem !important;
      font-size: 1.5rem !important;
      z-index: 1;
      border-top-left-radius: 1rem;
    }
    .questionList .courseData .reply_tag{
      background-color: #f5f5f5;
      border-radius: 3rem;
      // padding-left: 0.3rem;
      height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // width: 90%;
      margin: 0 1rem;
    }
    .questionList .courseData .reply_tag .van-tag{
      height: 2.8rem !important;
      // width: 3rem !important;
      border-radius: 3rem;
      padding: 0.1rem 1rem !important;
      font-size: 1.8rem !important;
    }
    .questionList .courseData .reply_tag .reply_text{
      padding-left: 0.2rem;
      font-size: 1.8rem;
      color: #646566;
    }
    .icon-img{
      width: 8rem;

    }
    .questionList .courseData .van-icon-back-top {
      -moz-transform: matrix(1, 0, 0, -1, 0, 0);
      -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
      -o-transform:matrix(1,0,0,-1,0,0);
      font-weight: 600;
    }
    .dataList11 {
      margin: 6rem 1rem;
    }
    .dataList11 .van-icon-back-top {
      -moz-transform: matrix(1, 0, 0, -1, 0, 0);
      -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
      -o-transform:matrix(1,0,0,-1,0,0);
    }
    .dataList11 .van-icon {
      font-size: 2.6rem;
      padding: 1rem;
    }

    .downloadPop .van-popup__close-icon--top-right {
        top: 1rem;
        right: 1rem;
    }
    .downloadPop .downTitle {
        position: fixed;
        z-index: 998;
    }
    .downloadPop .downTitle .van-icon-close {
        color: #c8c9cc;
        font-size: 4rem;
        cursor: pointer;
        padding-right: 1rem;
    }
    .van-icon-arrow-left:before {
        content: "\e668";
    }
    .van-nav-bar__arrow {
         margin-left: 0.5rem!important;
         font-size: 3.6rem!important;
         color: #323233!important;
    }
</style>
