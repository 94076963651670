<template>
  <div class="examClass">
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        {{questionCateTitle}}
      </template>
    </van-nav-bar>

    <div class="question-subject-tab" v-if="subjectList.length>0">
      <van-tabs class="subject-tab" v-model:active="active" color="#1989fa" animated swipeable @click="clickTab" style="z-index: 0">
        <van-tab :title="item.abbreviation_name" :name="item.id" v-for="(item,index) in subjectList" :key="index">
          <v-touch v-on:swipeleft="getCurrentTest(1,index)"  v-on:swiperight="getCurrentTest(2,index)" :swipe-options="{ direction: 'horizontal'}" style="touch-action: pan-y!important;">
            <div v-if="testPaperList.length > 0">
              <van-cell class="question-exam" center
                        v-for="(item2, index2) in testPaperList"
                        :key="index2" ><!--@click="ksxxFun(item2)"-->
                <template #title>
                  <div v-if="(item2.status || item2.on_trial == 1 ) && item2.status2 == 1" @click="questionDetail(item2.id)">{{item2.title}}</div>
                  <div v-else @click="orderMsg(item2.id,item2.status2)">{{item2.title}}</div>
                </template>
                <template #icon>
                  <van-icon class="left-icon01" :name="icon_exam"/>
                </template>
                <template #right-icon>
                  <div v-if="(item2.status || item2.on_trial == 1) && item2.status2 == 1">
                    <van-tag v-if="item2.questionStatus" color="#1989fa" round plain size="medium"
                             @click="questionDetail(item2.id)">重做
                    </van-tag>
                    <van-icon v-else class="right-icon" name="edit" @click="questionDetail(item2.id)"/>
                  </div>
                  <div v-else>
                    <!--未购买 提示购买-->
                    <van-icon @click="orderMsg(item2.id,item2.status2)" class="right-icon" name="lock"/>
                  </div>
                </template>
                <template #label>
                  <span v-if="(item2.status || item2.on_trial == 1) && item2.status2 == 1" @click="questionDetail(item2.id)">共{{ item2.question_num }}题，{{ item2.count }}人做过</span>
                  <span v-else @click="orderMsg(item2.id,item2.status2)">共{{ item2.question_num }}题，{{ item2.count }}人做过</span>
                </template>

              </van-cell>
            </div>
          </v-touch>
          <!-- <van-empty
                  v-else
                  description="暂无试卷"
                  image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                  image-size="24rem">
              <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus">选课中心
              </van-button>
          </van-empty> -->
        </van-tab>
      </van-tabs>
    </div>
    <div v-else>
      <van-empty
          description="暂无试卷"
          image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
          image-size="24rem">
        <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus" @click="mySchool">联系客服</van-button>
      </van-empty>
    </div>
    <!-- 弹出框（试卷状态） -->
    <van-popup v-model="showKS" class="question-content1" v-if="currentInfo.state == '0'">
      <div class="question-content-popup">
        <van-icon name="warning-o" color="#ee0a24"/>
        <div>该试卷还在整理中，暂未上架，敬请期待哦~</div>
      </div>
    </van-popup>
    <!-- 弹出层（考试信息） -->
    <van-popup v-model="showKS" :style="{ height: '70%',width:'95%' }" v-if="currentInfo.state == '1'">
      <examKsxx :currentInfo="currentInfo" @closeFun="closeFun"></examKsxx>
    </van-popup>
    <pageLoading  v-show="showLoading"/>
  </div>
</template>

<script>
// 考试信息的弹框
import examKsxx from '@/modules/exam_ksxx'
import pageLoading from "@/components/pageLoading";
import {Toast} from "vant";
import {testPaperSubjectList, testPaperList, testPaperDetail} from "@/request/api";

export default {
  name: 'QuestionList',
  metaInfo: {
    title: '题库列表', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
      {
        name: "description",
        content: "描述",
      },
    ]
  },
  components: {examKsxx,pageLoading},
  data() {
    return {
      activeNames: ['1'],
      active: '',
      item: '',
      selectChapter: true,
      selectPaper: false,
      icon_exam: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_exam.png",
      icon_plus: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_plus.png",
      icon_minus: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_minus.png",
      icon_choice: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_choice.png",
      show: false,
      showKS: false,
      msg: '',
      // 考试数据
      examObj: {},
      // 传给子组件的考当前试信息
      currentInfo: {},

      question_type_id: '',
      questionCateTitle: '题库',
      subject_id: '',
      subjectList: [],
      testPaperList: [],
      appOrH5:false,
      showLoading:true,
    }
  },
  created() {
    this.question_type_id = this.$route.query.id;
    this.questionCateTitle = this.$route.query.title;
    this.appOrH5 = this.$route.query.appOrH5;
    // 获取考试列表
    // this.getData();
    this.getTestPaperTab();
  },

  methods: {
    mySchool(){
      this.$router.push('/MySchool')
    },
    // 点击考试信息的遮罩层
    closeFun() {
      this.showKS = false
    },

    onQuestionChapter() {
      if (this.selectChapter == true) {
        this.selectChapter = false
      } else if (this.selectChapter == false) {
        this.selectChapter = true
      }
    },
    //试卷详情
    ksxxFun(val) {
      this.showKS = true
      this.currentInfo = val
    },

    toPaper() {
      // this.$router.push('/PaperDetail')
      this.$router.push('/Zxks')
    },
    getData() {
      this.examObj = [
        {
          title: "2018年中药一（历年真题）2018年中药一（历年真题）",
          examType: '1', //类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷
          state: '1', //考试的状态   0:暂未更新   1：可以做题
          totalScore: 120, //试卷总分
          passScore: 72, //试卷及格分
          textNum: 120, //试卷总题数
          time: 120, //试卷总题数
          userSum: 4059,
          showEdit: true,
          showBtn: false,
          textType: [
            {text_ty: '2', textSub: '40', text_score: '40'},
            {text_ty: '6', textSub: '60', text_score: '60'},
            {text_ty: '5', textSub: '10', text_score: '10'},
            {text_ty: '3', textSub: '10', text_score: '10'}
          ],
        },
        {
          title: "2019年中药一（历年真题）",
          examType: '1', //类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷
          state: '1', //考试的状态   0:暂未更新   1：可以做题
          totalScore: 120, //试卷总分
          passScore: 72, //试卷及格分
          textNum: 120, //试卷总题数
          time: 120, //试卷总题数
          userSum: 4059,
          showEdit: true,
          showBtn: false,
          textType: [
            {text_ty: '2', textSub: '40', text_score: '40'},
            {text_ty: '6', textSub: '60', text_score: '60'},
            {text_ty: '5', textSub: '10', text_score: '10'},
            {text_ty: '3', textSub: '10', text_score: '10'}
          ],
        },
        {
          title: "2020年中药一（历年真题）",
          examType: '1', //类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷
          state: '1', //考试的状态   0:暂未更新   1：可以做题
          totalScore: 120, //试卷总分
          passScore: 72, //试卷及格分
          textNum: 120, //试卷总题数
          time: 120, //试卷总题数
          userSum: 4059,
          showEdit: false,
          showBtn: true,
          textType: [
            {text_ty: '2', textSub: '40', text_score: '40'},
            {text_ty: '6', textSub: '60', text_score: '60'},
            {text_ty: '5', textSub: '10', text_score: '10'},
            {text_ty: '3', textSub: '10', text_score: '10'}
          ],
        },
        {
          title: "2021年中药一（历年真题）",
          examType: '1', //类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷
          state: '0', //考试的状态   0:暂未更新   1：可以做题
          totalScore: 120, //试卷总分
          passScore: 72, //试卷及格分
          textNum: 120, //试卷总题数
          time: 120, //试卷总题数
          userSum: 4059,
          showEdit: true,
          showBtn: false,
          textType: [
            {text_ty: '2', textSub: '40', text_score: '40'},
            {text_ty: '6', textSub: '60', text_score: '60'},
            {text_ty: '5', textSub: '10', text_score: '10'},
            {text_ty: '3', textSub: '10', text_score: '10'}
          ],
        },
      ]
    },

    onBack() {
      // history.back();
      // this.$router.push('/questionH5')
      let url = 'Question';
      if(window.android != undefined) {
        if(window.android.isapp() == true) {
          url = 'QuestionH5';
        }
      }
      this.$router.push({
        name:url,
        params:{

        },
        query:{

        }
      });
    },
    getTestPaperTab() {
      let that = this;
      let question_type_id = that.question_type_id;
      testPaperSubjectList({type: question_type_id})
          .then(res => {
            // this.showLoading = false;
            if (res.data.code == 1) {
              that.subjectList = res.data.data.data;
              if(res.data.data.data.length <= 0){
                that.showLoading = false;
              }
              try {
                res.data.data.data.forEach(function (item, index) {
                  console.log(index);
                  that.subject_id = item.id;
                  that.getTestPaper();
                  throw Error();
                })
              }catch (e){
                console.log("益昌");
                console.log(e);
              }

            }else{
              this.showLoading = false;
            }
          })
          .catch(err => {
            this.showLoading = false;
            console.log("error");
            console.log(err);
          })
    },
    getCurrentTest(val,index) {
      if(val == 1) {
        if(this.subjectList[index+1]) {
          // console.log('左',this.subjectList[index+1]);
          this.subject_id = this.subjectList[index+1].id;
          this.getTestPaper();
        }
      }
      if(val == 2) {
        if(this.subjectList[index-1]) {
          // console.log('右',this.subjectList[index-1]);
          this.subject_id = this.subjectList[index-1].id;
          this.getTestPaper();
        }
      }
    },
    getTestPaper() {
      let that = this;
      let question_type_id = that.question_type_id;
      let subjectId = that.subject_id;
      testPaperList({type: question_type_id, subjectId})
          .then(res => {
            this.showLoading = false;
            if (res.data.code == 1) {
              that.testPaperList = res.data.data.data;
            }
          })
          .catch(err => {
            this.showLoading = false;
            console.log("error");
            console.log(err);
          })
    },
    clickTab(name, title) {
      this.subject_id = name
      this.getTestPaper();
    },
    //试卷详情
    questionDetail(id) {
      let that = this;
      testPaperDetail({test_paper_id: id})
          .then(res => {
            if (res.data.code == 1) {
              that.showKS = true
              that.currentInfo = res.data.data.data

              // console.log(that.currentInfo)
              // console.log({
              //     title: "2019年中药一（历年真题）",
              //     examType: '1', //类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷
              //     state: '1', //考试的状态   0:暂未更新   1：可以做题
              //     totalScore: 120, //试卷总分
              //     passScore: 72, //试卷及格分
              //     textNum: 120, //试卷总题数
              //     time: 120, //试卷总题数
              //     userSum: 4059,
              //     showEdit: true,
              //     showBtn: false,
              //     textType: [
              //         {text_ty: '2', textSub: '40', text_score: '40'},
              //         {text_ty: '6', textSub: '60', text_score: '60'},
              //         {text_ty: '5', textSub: '10', text_score: '10'},
              //         {text_ty: '3', textSub: '10', text_score: '10'}
              //     ],
              // })
            }
          })
          .catch(err => {
            console.log("error");
            console.log(err);
          })
    },
    //未购买 提示
    orderMsg(id,status) {
      if(status != 1) {
        Toast({
          message: '试卷未开放',
          icon: 'lock',
          position: 'top',
          duration: 1000,
        });
      } else {
        Toast({
          message: '题库未购买',
          icon: 'lock',
          position: 'top',
          duration: 1000,
        });
      }
    }
  },
  computed: {},
}
</script>

<style lang="less">
// #app {
//     background-color: #ffffff;
//     -webkit-overflow-scrolling: touch;
// }

.examClass .van-popup {
  border-radius: 1.5rem;
  overflow: hidden;
}

.question-subject-tab {
  padding: 12.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
}

.question-subject-tab .subject-tab .van-tabs__wrap {
  position: fixed;
  top: 6rem;
  left: 0;
  z-index: 99;
  width: 100%;
}

.question-subject-tab .subject-tab .van-tabs__line {
  bottom: 0.5rem !important;
  left: 0 !important;
  z-index: 1;
  width: 3.2rem !important;
  height: 0.5rem !important;
  border-radius: 2rem !important;
}

.question-subject-tab .subject-tab .van-tab .van-tab__text {
  padding: 0 0.6rem;
}

.question-subject-tab .subject-tab .van-tab--active .van-tab__text {
  font-size: 2.2rem !important;
}

// .question-subject-tab .subject-tab .van-tabs__content {
//   margin: 1rem 0;
// }

.question-exam {
  padding: 1.5rem 1rem !important;
}

.question-exam .left-icon01 {
  font-size: 4rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam .right-icon {
  font-size: 3rem !important;
}

.question-exam .van-icon-edit {
  font-size: 3rem !important;
  color: #1989fa;
  padding: 0 1.3rem !important;
}

.question-exam .van-cell__title {
  padding-right: 1rem;
}

.question-exam .van-cell__label {
  line-height: 2.4rem !important;
  font-size: 1.8rem !important;
  padding-top: 1rem;
}

.question-child {
  margin-left: 3rem;
}

.question-child .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 2.3rem;
}

.question-progress {
  float: left;
  margin: 0.8rem 1rem 0 0 !important;
  display: flex;
  justify-content: center;
}

.question-progress .van-progress__pivot {
  display: none;
}

.question-exam-chapter {
  font-weight: 600 !important;
  font-size: 2.1rem;
}

.question-exam-collapse .van-collapse-item {
  padding: 0rem 1rem 0rem 3.5rem !important;
}

.question-exam-collapse .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 1.3rem;
}

.question-exam-collapse .van-collapse-item .van-collapse-item__title {
  align-items: center;
  padding: 1.5rem 0 !important;
}

.question-exam-collapse .van-collapse-item .van-collapse-item__title:active {
  background-color: #ffffff;
}

.question-exam-collapse .van-collapse-item .question-exam-list {
  // line-height: 6rem;
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.question-exam-collapse .van-collapse-item .question-exam-list .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .right-icon {
  font-size: 3rem !important;
  padding: 1.5rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .question-exam-list .label {
  font-size: 1.8rem;
  color: #969799;
}

.question-exam-collapse .van-collapse-item .van-icon-arrow {
  line-height: 6rem;
  display: flex;
  align-items: center;
  font-weight: 600;
}


.question-exam-collapse::after {
  border-width: 0 !important;
}

.van-collapse-item__title::after {
  display: none !important;
}

.question-exam-collapse .van-collapse-item .question-exam-children {
  padding: 1.5rem 0 !important;
}

.question-exam-collapse .van-collapse-item .question-exam-children .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .question-exam-children .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 1.21rem;
}

.question-exam-collapse .van-collapse-item .question-exam-children .van-cell__label {
  line-height: 2.4rem !important;
  font-size: 1.8rem !important;
  padding-top: 1rem;
}

.question-exam-collapse .van-collapse-item .question-exam-children::after {
  border-width: 0 !important;
}

.question-content1 {
  border-radius: 1rem;
}

.question-content1 .question-content-popup {
  padding: 2rem 1.5rem;
  border-radius: 1rem;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
}

.question-content1 .question-content-popup .van-icon {
  padding: 1rem 1.5rem;
  font-size: 3.5rem;
  line-height: 3rem;
}
</style>




